.Registration {
  justify-content: space-between;
  padding-bottom: 20px; }
  .Registration-row {
    display: flex;
    width: 100%;
    padding: 20px 0;
    align-items: center; }
    .Registration-row.heading {
      background: #dee7ed; }
    .Registration-row .item-note {
      color: #999; }
  .Registration-item-fill {
    flex-grow: 1;
    padding-left: 20px; }
  .Registration-item-name {
    max-width: calc(60% - 20px); }
  .Registration-item-20pc {
    width: 20%; }
  .Registration-footer {
    text-align: center; }
    .Registration-footer-text {
      background: #fbdab5;
      padding: 20px 0; }
