.Client-ListItem {
  background: #dfe7ed;
  padding-top: 30px;
  min-height: 90px;
  margin-bottom: 10px;
  text-align: center; }

.Registration-ListItem {
  background: #dfe7ed;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 10px 40px 10px 30px;
  margin-bottom: 10px; }
  .Registration-ListItem.create {
    background: #9cdcd8; }
  .Registration-ListItem .icon {
    position: absolute;
    right: 10px; }
