.layout {
  background: white;
  height: 100vh;
  display: flex;
  flex-direction: column; }

body, body * {
  margin: 0;
  padding: 0;
  font-family: roboto; }

.Heading {
  padding: 20px; }

.Header {
  padding: 20px; }

.Page {
  display: flex;
  flex-direction: column;
  flex-grow: 1; }
  .Page .SearchBox {
    margin: 10px;
    padding: 10px;
    width: 300px; }

.Page-Scroll {
  flex-grow: 1; }
